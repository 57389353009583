<script setup lang="ts">
import {defineProps, ref, watch} from 'vue';
import useGlobalStateStore from "@/Store/GlobalStateStore";

const props = defineProps({
  Title: String,
  Content: String,
  ImagePath: String,
  ClickWebPath:String,
});

enum Enum_Theme {
  Dark,
  Light,
}

const GlobalStyleStore=useGlobalStateStore();

//打开指定页
const handleClick = () => {
  if (props.ClickWebPath) {
    window.open(props.ClickWebPath, '_blank');
  }
};


const ThemeTitleText=ref("#FFF")
const ThemeTitleTextHover=ref("#57E1D9")
const ThemeContentText=ref("#cecece")

watch(GlobalStyleStore, () => {
  if (GlobalStyleStore.CurrentTheme === Enum_Theme.Dark){
    ThemeTitleText.value='#FFF';
    ThemeTitleTextHover.value='#57E1D9';
    ThemeContentText.value='#cecece';
  }else{
    ThemeTitleText.value='#545454';
    ThemeTitleTextHover.value='#f77373';
    ThemeContentText.value='#484848';
  }
});


</script>

<template>
  <div class="InfoBoxBG">
    <div class="InfoBoxContainer">
      <div class="Info_Text">
        <div class="TitleText" @click="handleClick">{{ props.Title }}</div>
        <div class="PartingLine"></div>
        <div class="ContentText">{{ props.Content }}</div>
      </div>
      <div class="Info_Image">
        <!-- 图片 -->
        <div class="InfoImage_Content">
          <img :src="props.ImagePath" alt="Example Image" @click="handleClick">
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.InfoBoxBG{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 100px 0;
}

.InfoBoxContainer {
  display: grid;
  width: 60%;
  height: 100%;
  grid-template-areas: "Info_Text Info_Image";
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 1fr;
  justify-content: flex-end;
  align-items: center;
}

/* 平板预设 */
@media screen and (max-width: 1200px) {
  .InfoBoxContainer {
    grid-template-areas: "Info_Text Info_Image";
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr;
    justify-content: flex-end;
    align-items: center;
  }
}

/* 手机预设 */
@media screen and (max-width: 768px) {
  .InfoBoxContainer {
    grid-template-columns: 1.7fr 1.2fr;
  }
}
@media screen and (max-width: 425px) {
  .InfoBoxContainer {
    grid-template-columns: 1fr 0;
  }
}
@media screen and (max-width: 375px) {
  .InfoBoxContainer {
    grid-template-columns: 1fr 0;
  }
}
@media screen and (max-width: 320px) {
  .InfoBoxContainer {
    grid-template-columns: 1fr 0;
  }
}


.Info_Text {
  grid-area: Info_Text;
  display: flex;
  flex-direction: column;
}
.Info_Image {
  grid-area: Info_Image;
}

.TitleText{
  font-size: 3rem;
  font-family: 资源圆体, sans-serif;
  font-weight: bold;
  color: v-bind(ThemeTitleText);
  caret-color: transparent;/*禁止输入*/
  user-select: none;/*禁止选中*/
  transition: color 0.8s ease;
  cursor: pointer;
}

/* 手机预设 */
@media screen and (max-width: 768px) {
  .TitleText {
    font-size: 2rem;
  }
}
@media screen and (max-width: 425px) {
  .TitleText {
    font-size: 2rem;
  }
}
@media screen and (max-width: 375px) {
  .TitleText {
    font-size: 2rem;
  }
}
@media screen and (max-width: 320px) {
  .TitleText {
    font-size: 2rem;
  }
}


.TitleText:hover{
  color: v-bind(ThemeTitleTextHover);
  transition: color 0.8s ease;
}

.PartingLine{
  background-color: v-bind(ThemeTitleTextHover);
  height: 8px;
  width: 15%;
  border-radius: 10px;
  margin: 5px 0 10px 0;
  caret-color: transparent;/*禁止输入*/
  pointer-events: none;/*禁止拖拽*/
  user-select: none;/*禁止选中*/
  transition: background-color 0.8s ease;
}

.ContentText{
  font-family: 资源圆体, sans-serif;
  margin: 8px 0 5px 0;
  color: v-bind(ThemeContentText);
  caret-color: transparent;/*禁止输入*/
  pointer-events: none;/*禁止拖拽*/
  user-select: none;/*禁止选中*/
  transition: color 0.8s ease;
}


.InfoImage_Content{
  caret-color: transparent;/*禁止输入*/
}

.InfoImage_Content img {;
  min-width: 350px;
  min-height: 197px;
  max-width: 350px;
  max-height: 197px;
  background-size: cover;
  border-radius: 10px;
  float: right; /* 图片从右侧开始显示 */
  position: relative;
  top: 15px;
  caret-color: transparent;/*禁止输入*/
  user-select: none;/*禁止选中*/
  cursor: pointer; /* 鼠标悬停时显示点击形状 */
}

/* 平板预设 */
@media screen and (max-width: 1440px) {
  .InfoImage_Content img  {
    min-width: 280px;
    min-height: 158px;
    max-width: 280px;
    max-height: 158px;
  }
}

@media screen and (max-width: 1200px) {
  .InfoImage_Content img  {
    min-width: 190px;
    min-height: 107px;
    max-width: 190px;
    max-height: 107px;
  }
}

/* 手机预设 */
@media screen and (max-width: 768px) {
  .InfoImage_Content img  {
    min-width: 190px;
    min-height: 107px;
    max-width: 190px;
    max-height: 107px;
    left: 5px;
  }
}
@media screen and (max-width: 425px) {
  .InfoImage_Content img  {
  display: none;
  }
}


</style>