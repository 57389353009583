<script setup lang="ts">
import useGlobalStateStore from "@/Store/GlobalStateStore";
import {ref, watch} from "vue";
import MainPage_Content_CHS from "@/components/MainPage/MainPage_Content_CHS.vue";

//———————————————— 枚举 ————————————————————
enum Enum_Theme {
  Dark,
  Light,
}

enum Enum_CurrentPage {
  MainPage,
}


//———————————————— 启动 ————————————————————


//———————————————— 变量声明 ————————————————————
const GlobalStyleStore=useGlobalStateStore();


//———————————————— 主题相关 ————————————————————
function OnUserClickLogo(){
  if (GlobalStyleStore.CurrentTheme===Enum_Theme.Dark){
    GlobalStyleStore.SetCurrentTheme(Enum_Theme.Light)
    console.log("切换到亮色主题");
  }else{
    GlobalStyleStore.SetCurrentTheme(Enum_Theme.Dark)
    console.log("切换到暗色主题");
  }
}

//主题颜色
const ThemeTopBarBackgroundColor = ref("rgba(28, 28, 28, 0.57)");
const ThemeTopBarColor = ref("#fff");
const ThemeItemColor = ref("#57E1D9");

watch(GlobalStyleStore, () => {
  if (GlobalStyleStore.CurrentTheme === Enum_Theme.Dark){
    ThemeTopBarBackgroundColor.value = 'rgba(28, 28, 28, 0.57)';
    ThemeTopBarColor.value='#fff'
    ThemeItemColor.value = '#57E1D9';
  }else{
    ThemeTopBarBackgroundColor.value = 'rgba(255, 255, 255, 0.57)';
    ThemeTopBarColor.value='#484848'
    ThemeItemColor.value = '#f77373';
  }
});


</script>

<template>
  <div class="MainPageBackground">
    <div class="TopBar">
      <div class="TopBarContent">
        <!-- Logo -->
        <div class="Logo">
          <img src="/Images/Logo.png" alt="Example Image" v-if="GlobalStyleStore.CurrentTheme===Enum_Theme.Dark" @click="OnUserClickLogo">
          <img src="/Images/Logo_1.png" alt="Example Image" v-if="GlobalStyleStore.CurrentTheme===Enum_Theme.Light" @click="OnUserClickLogo">
        </div>
        <!-- 分隔栏 -->
        <div class="PageBar">
        </div>
        <div class="Login">
          <span class="item">注册</span>
          <span class="separator">|</span>
          <span class="item">登入</span>
        </div>
      </div>
    </div>
    <div class="Content">
    <!-- 具体内容 -->
      <MainPage_Content_CHS v-if="GlobalStyleStore.CurrentPage===Enum_CurrentPage.MainPage"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

/*主页面背景*/
.MainPageBackground {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* 置顶条背景 */
.TopBar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color:v-bind(ThemeTopBarBackgroundColor);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  color: v-bind(ThemeTopBarColor);
  display: flex;
  z-index: 100;
  backdrop-filter: blur(5px);
  transition: background-color 0.8s ease;
}

/* 置顶条容器 */
.TopBarContent {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: "Logo PageBar Login";
  grid-template-columns: 2fr 8fr 1.2fr;
  grid-template-rows: 1fr;
}

/* 平板适配 */
@media screen and (max-width: 1200px) {
  .TopBarContent {
    grid-template-columns: 2fr 7fr 2fr;
  }
}

@media screen and (max-width: 768px) {
  .TopBarContent {
    grid-template-columns: 2fr 4fr 2fr;
  }
}

/* 移动设备适配 */
@media screen and (max-width: 425px) {
  .TopBarContent {
    grid-template-columns: 2fr 1fr 2fr;
  }
}

@media screen and (max-width: 320px) {
  .TopBarContent {
    grid-template-columns: 2fr 1fr 4fr;
  }
}

/*Logo容器*/
.Logo {
  grid-area: Logo;
  margin: 6px 3px 20px 6px;
  padding: 5px;
  caret-color: transparent;/*禁止输入*/
  position: relative;
  top: 13%;
  left: 8%;
}
.Logo img {;
  max-width: 210px;
  user-select: none;/*禁止选中*/
}

/* 移动设备适配 */
@media screen and (max-width: 425px) {
  .Logo img {
    max-width: 120px;
  }
}

/*顶部页面分割条（实际功能未启用）*/
.PageBar {
  grid-area: PageBar;
  padding: 15px 5px 15px 15px;
  font-weight: bold;
  caret-color: transparent;/*禁止输入*/
  pointer-events: none;/*禁止拖拽*/
  user-select: none;/*禁止选中*/
}

/*登入条*/
.Login {
  grid-area: Login;
  padding: 15px 5px 15px 15px;
  position: relative;
  top: 7%;
  right: 5%;
  caret-color: transparent;/*禁止输入*/
  user-select: none;/*禁止选中*/
}

/* 移动设备适配 */
@media screen and (max-width: 425px) {
  .Login {
    top: 4%;
    left: 5%;
    right:unset;
  }
}


/*置顶栏-页面选项*/
/*分割栏每项内容*/
.item {
  margin: 0 10px;
  font-family: 资源圆体, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: v-bind(ThemeTopBarColor);
  transition: color 0.8s ease, font-size 0.2s ease;
}

.item:hover {
  color: v-bind(ThemeItemColor);
}

/* 移动设备适配 */
@media screen and (max-width: 425px) {
  .item {
    font-size: 1rem;
  }
}

/*分割线*/
.separator {
  margin: 0 5px;
  bottom: 2px;
  position: relative;
}

/*下面部分内容区*/
.Content {
  width: 100%;
  height: auto;
  position: relative;
  top: 120px;
}

</style>