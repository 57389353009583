<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { Video } from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import '@splidejs/splide/css';
import {ref} from "vue";

const mediaItems = [
  { type: 'video', src: '/Images/Carousel/Carousel_4.mp4' ,title:'测试视频-1'},
  { type: 'image', src: '/Images/Carousel/Carousel_1.png',title:'测试图-1' ,href:'#'},
  { type: 'image', src: '/Images/Carousel/Carousel_2.png',title:'测试图-2' ,href:'#'},
  { type: 'image', src: '/Images/Carousel/Carousel_3.png',title:'测试图-3' ,href:'#'},
  { type: 'image', src: '/Images/Carousel/Carousel_4.png' ,title:'测试图-4',href:'#'},
  // ...其他媒体项
];


const options = {
  rewind: true,
  heightRatio: 0.5625,
  autoplay: true, // 添加自动播放设置
  interval: 5000,// 设置自动播放的间隔时间为3秒
};

const extensions = { Video };
// <img src="">

// 添加响应式数据来控制标题的透明度
const titleOpacity = ref(0);

// 鼠标移入时改变透明度
const hoverIn = () => {
  titleOpacity.value = 1;
};

// 鼠标移出时恢复透明度
const hoverOut = () => {
  titleOpacity.value = 0;
};

// 添加openLink方法来处理点击事件
const openLink = (href: string) => {
  window.open(href, '_blank'); // 在新窗口或标签页中打开链接
};
</script>


<template>
  <div class="wrapper">
    <Splide :options="options" :extensions="extensions">
      <SplideSlide v-for="(item, index) in mediaItems" :key="index"
                   @mouseover="hoverIn" @mouseleave="hoverOut">
        <div class="CarouselTitle" :style="{ opacity: titleOpacity }">{{item.title}}</div>
        <img v-if="item.type === 'image'" :src="item.src" :alt="`Image ${index + 1}`" @click="openLink(item.href)">
        <video v-else-if="item.type === 'video'" controls :src="item.src" poster="/Images/Carousel/Preview_4.png"></video>
      </SplideSlide>
    </Splide>
  </div>
</template>

<style scoped lang="scss">

.wrapper{
  display: flex;
  justify-content: center;
  margin: 22px 10px 30px 10px;
}

.splide__slide img,.splide__slide video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: border-radius 3s ease;
  box-sizing: border-box;
}
.splide__slide img{
  cursor: pointer;
}

.splide__slide img:hover,.splide__slide video:hover {
  /*border: 0px solid #20B2AA;*/
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.splide{
  width: 60%;
  height: 100%;
}


.CarouselTitle{
  width: 800px;
  position: fixed;
  font-family: 资源圆体, sans-serif;
  font-weight: bold;
  font-size: 1.1rem;
  color: rgba(21, 21, 21, 0.77);
  padding: 10px 5px 5px 15px;
  transition: opacity 0.3s ease;
  opacity: 0;
}


</style>