<script setup lang="ts">

import InfoBox_Left from "@/components/InfoBox_Left.vue";
import CarouselWindow from "@/components/CarouselWindow.vue";
import {AnOutlinedBilibili, AnOutlinedX, MdEmail, SiSinaweibo,CoBrandYoutube  } from "@kalimahapps/vue-icons";
import useGlobalStateStore from "@/Store/GlobalStateStore";
import {ref, watch} from "vue";

enum Enum_Theme {
  Dark,
  Light,
}

const GlobalStyleStore=useGlobalStateStore();
async function OnCLickEmail(){
  try {
    await navigator.clipboard.writeText("info@lesjeuxa.com");
    alert("Email address has been copied to the clipboard, feel free to contact us (๑′ᴗ‵๑)❤");
  } catch (error) {
    console.error("Unable to copy to clipboard：", error);
  }
}
function OnCLickX(){
  window.open("https://x.com", "_blank");
}
function OnCLickWeiBo(){
  window.open("https://weibo.com/", "_blank");
}
function OnCLickBilibili(){
  window.open("https://www.bilibili.com/", "_blank");
}

function OnCLickYoutuber(){
  window.open("https://www.youtube.com/@lesjeuxa", "_blank");
}

const ThemeMainSloganColor=ref("#FFF")
const ThemeMainSloganCruxColor=ref("#57E1D9")
const ThemeSubSlogan=ref("#FFF")
const ThemeIconBackgroundColor=ref("#3fb7b0")
const ThemeIconBackgroundHoverColor=ref("#57E1D9")
const ThemeBottomBG=ref("#242424")
const ThemeBottomPartingLine=ref("#FFFFFF26")
const ThemeCopyrightFiling=ref("#FFF")


watch(GlobalStyleStore, () => {
  if (GlobalStyleStore.CurrentTheme === Enum_Theme.Dark){
    ThemeMainSloganColor.value='#FFF';
    ThemeMainSloganCruxColor.value='#57E1D9';
    ThemeSubSlogan.value='#FFF'
    ThemeIconBackgroundColor.value='#3fb7b0';
    ThemeIconBackgroundHoverColor.value='#57E1D9';
    ThemeBottomBG.value='#242424';
    ThemeBottomPartingLine.value='#FFFFFF26';
    ThemeCopyrightFiling.value='rgba(255,255,255,0.6)';
  }else{
    ThemeMainSloganColor.value='#545454';
    ThemeMainSloganCruxColor.value = '#f77373';
    ThemeSubSlogan.value='#1a1a1a'
    ThemeIconBackgroundColor.value='#de5959';
    ThemeIconBackgroundHoverColor.value='#f77373';
    ThemeBottomBG.value='#efefef';
    ThemeBottomPartingLine.value='rgba(185,185,185,0.1)';
    ThemeCopyrightFiling.value='rgba(0,0,0,0.6)';
  }
});

</script>

<template>
  <div class="MainPageBG">
    <div class="SloganContainer">
      <div class="MainSlogan">
        <span class="MainSlogan Crux">Tech</span>&nbsp;R&D&nbsp;
        <span class="MainSlogan Crux"> Art</span>&nbsp;Creation&nbsp;
        <span class="MainSlogan Crux">New Culture</span>&nbsp;Promotion
      </div>
      <div class="SubSlogan">
        Founded in 2021, LESJEUXA is a cutting-edge game design company. We specialize in Tech R&D, art creation, and new culture promotion, delivering exceptional and pioneering "LESJEUXA Solutions" to markets across Asia and the globe.
      </div>
      <div class="ContactInformation">
        <!-- 电子邮件 -->
        <div class="icon-container blue-bg" @click="OnCLickEmail">
          <MdEmail class="white-icon" />
        </div>
        <!-- X -->
        <div class="icon-container blue-bg"  @click="OnCLickX">
          <AnOutlinedX class="white-icon" />
        </div>
        <!-- 微博 -->
        <div class="icon-container blue-bg"  @click="OnCLickWeiBo">
          <SiSinaweibo class="white-icon" />
        </div>
        <!-- Bilibili -->
        <div class="icon-container blue-bg"  @click="OnCLickBilibili">
          <AnOutlinedBilibili class="white-icon" />
        </div>
        <!-- Youtube -->
        <div class="icon-container blue-bg"  @click="OnCLickYoutuber">
          <CoBrandYoutube  class="white-icon" />
        </div>
      </div>
    </div>
  </div>
  <!-- 滚动窗 -->
  <CarouselWindow/>
  <!-- 信息窗 -->
  <InfoBox_Left Title="Notice 1" Content="We hereby solemnly announce that this notice is of great importance. Please pay attention, as the content of this notice is critical and must not be ignored. Everyone is required to read this notice carefully and follow the instructions strictly. If you have any questions or need further clarification, please contact us promptly so we can provide detailed explanations and guidance. Thank you!" ImagePath="\Images\Info\Announcement.png" ClickWebPath="#"/>
  <InfoBox_Left Title="Notice 2" Content="Here is the content of the notice; feel free to write anything." ImagePath="\Images\Info\Announcement.png" ClickWebPath="#"/>
  <!-- 底部 -->
  <div class="BottomBG">
    <div class="BottomPartingLine"></div>
    <div class="BottomContent">
      <div class="CopyrightFiling">
        Copyright  2021-2024 LESJEUXA.COM 遊晰概念有限公司
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.MainPageBG{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row ;
  width: 100%;
  height: 100%;
  position: relative;
}

.SloganContainer {
  display: grid;
  width: 59.5%;
  height: 100%;
  grid-template-areas: "MainSlogan SubSlogan"
  "MainSlogan ContactInformation";
  grid-template-columns: 1fr 1.27fr;
  grid-template-rows: 1fr 1fr;

}

/* 平板适配 */
@media screen and (max-width: 1440px) {
  .SloganContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 59.5%;
    height: 100%;
    grid-template-areas:unset;
    grid-template-columns:unset;
    grid-template-rows:unset;
  }
}


/* 平板适配 */
@media screen and (max-width: 768px) {
  .SloganContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 59.5%;
    height: 100%;
    grid-template-areas:unset;
    grid-template-columns:unset;
    grid-template-rows:unset;
  }
}

.MainSlogan {
  grid-area: MainSlogan;
  display: flex;
  font-weight: bold;
  font-family:sans-serif;
  font-size: 2.5rem;
  color: v-bind(ThemeMainSloganColor);
  flex-wrap: wrap;
  caret-color: transparent;/*禁止输入*/
  pointer-events: none;/*禁止拖拽*/
  user-select: none;/*禁止选中*/
  transition: color 0.8s ease;
}

/* 平板适配 */
@media screen and (max-width: 1440px) {
  .MainSlogan {
    font-size: 1.5rem;
  }
}

/* 平板适配 */
@media screen and (max-width: 768px) {
  .MainSlogan {
    font-size: 1.5rem;
  }
}


/* 手机预设 */
@media screen and (max-width: 425px) {
  .MainSlogan {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 320px) {
  .MainSlogan {
    font-size: 1.3rem;
  }
}


.MainSlogan.Crux{
  color: v-bind(ThemeMainSloganCruxColor);
  transition: color 0.8s ease;
}

.SubSlogan {
  grid-area: SubSlogan;
  color: v-bind(ThemeSubSlogan);
  font-family:资源圆体, sans-serif;
  position: relative;
  top: 10px;
  caret-color: transparent;/*禁止输入*/
  pointer-events: none;/*禁止拖拽*/
  user-select: none;/*禁止选中*/
  transition: color 0.8s ease;
}
.ContactInformation {
  grid-area: ContactInformation;
  position: relative;
  top: 25px;
  caret-color: transparent;/*禁止输入*/
  user-select: none;/*禁止选中*/
}

/* 平板适配 */
@media screen and (max-width: 1440px) {
  .ContactInformation {
    top: 15px;
    margin: 5px;
  }
}

/* 平板适配 */
@media screen and (max-width: 768px) {
  .ContactInformation {
    top: 15px;
    margin: 5px;
  }
}


/*联系方式图标*/
.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* 或者你想要的任何尺寸 */
  height: 40px; /* 或者你想要的任何尺寸 */
  border-radius: 50%; /* 使容器呈现圆形 */
  margin: 0 14px 0 0; /* 使图标之间有间距 */
  cursor: pointer; /* 使图标呈现为可点击状态 */
}

/* 平板适配 */
@media screen and (max-width: 768px) {
  .icon-container {
    width: 25px;
    height: 25px;
  }
}


.icon-container:hover {
  background-color: v-bind(ThemeIconBackgroundHoverColor); /* 鼠标悬浮时改变背景色 */
  transition: background-color 0.8s ease;
}

.blue-bg {
  background-color: v-bind(ThemeIconBackgroundColor); /* 原型蓝底 */
  transition: background-color 0.8s ease;
}


.white-icon {
  fill: #FFF; /* 将图标颜色设置为白色 */
  width: 25px;
  height: 25px;
}

/* 平板适配 */
@media screen and (max-width: 768px) {
  .white-icon {
    width: 17px;
    height: 17px;
  }
}

.BottomBG{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background:v-bind(ThemeBottomBG) ;
  transition: background 0.8s ease;
}

.BottomPartingLine{
  background-color: v-bind(ThemeBottomPartingLine);
  height: 2px;
  width: 100%;
  border-radius: 10px;
  caret-color: transparent;/*禁止输入*/
  pointer-events: none;/*禁止拖拽*/
  user-select: none;/*禁止选中*/
  transition: background-color 0.8s ease;
}


.BottomContent {
  width: 100%;
  text-align: center;
}

.CopyrightFiling{
  color: v-bind(ThemeCopyrightFiling);
  font-family: 资源圆体, sans-serif;
  margin: 10px 0 15px 0;
  font-size: 0.9rem;
  opacity: 1;
  caret-color: transparent;/*禁止输入*/
  user-select: none;/*禁止选中*/
  transition: color 0.8s ease;
}

.CopyrightFiling:hover {
  color:v-bind(ThemeMainSloganCruxColor);
}

</style>