// src/stores/projectStore.ts
import { defineStore } from 'pinia';

enum Enum_CurrentPage {
    MainPage,
}

enum Enum_Theme {
    Dark,
    Light,
}


export const useGlobalStateStore = defineStore('GlobalState', {
    state: () => ({
        CurrentPage: Enum_CurrentPage.MainPage, // 当前页面
        CurrentTheme: Enum_Theme.Dark, // 当前主题
    }),
    actions: {
        SetCurrentPage(NewPage: Enum_CurrentPage) {
            this.CurrentPage = NewPage;
        },
        SetCurrentTheme(NewTheme: Enum_Theme) {
            this.CurrentTheme = NewTheme;
        }
    },
});

export default useGlobalStateStore;