<script setup lang="ts">
import useGlobalStateStore from "@/Store/GlobalStateStore";
import {ref, watch} from "vue";

const GlobalStyleStore=useGlobalStateStore();
enum Enum_Theme {
  Dark,
  Light,
}

const ThemeBackgroundColor = ref("#202020");

watch(GlobalStyleStore, () => {
  if (GlobalStyleStore.CurrentTheme === Enum_Theme.Dark){
    ThemeBackgroundColor.value = '#202020';
  }else{
    ThemeBackgroundColor.value = '#fff';
  }
});



</script>

<template>
  <div class="AppBackground">

    <!-- 路由 -->
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {

}

.AppBackground{
  background-color: v-bind(ThemeBackgroundColor);
  width: 100%;
  height: 100%;
  z-index: -1; /* 如果需要的话 */
  overflow-y: auto; /* 允许垂直滚动 */
  overflow-x: hidden; /*禁止水平滚动 */
  transition: background-color 0.8s ease;
}
/* 自定义滚动条样式 */
::-webkit-scrollbar {
  width: 0; /* 滚动条宽度 */
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* 滚动条轨道背景颜色 */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* 滚动条拇指背景颜色 */
  border-radius: 6px; /* 滚动条拇指圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.7); /* 鼠标悬停时的滚动条拇指背景颜色 */
}

@font-face {
  font-family: '资源圆体';
  src: url('~@/assets/Fonts/资源圆体-N.ttf') format('truetype');
}



html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden; /* 防止水平滚动条 */
}
</style>
