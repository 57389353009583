import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainPage_CHS from '@/views/CHS/MainPage_CHS.vue';
import MainPage_CHT from '@/views/CHT/MainPage_CHT.vue';
import MainPage_EN from '@/views/EN/MainPage_EN.vue';
import MainPage_JPN from '@/views/JPN/MainPage_JPN.vue';

const routes: Array<RouteRecordRaw> = [
  { path: '/chs', component: MainPage_CHS, meta: { title: '遊晰概念 - 技术研发、艺术创造、新文化传播' } },
  { path: '/cht', component: MainPage_CHT, meta: { title: '遊晰概念 - 技術研發、藝術創造、新文化傳播' } },
  { path: '/en', component: MainPage_EN, meta: { title: 'LESJEUXA - Tech R&D, Art Creation, New Culture Promotion' } },
  { path: '/ja', component: MainPage_JPN, meta: { title: '遊晰概念 - 技術研発、芸術創造、新文化伝播' } },
  { path: '/', component: MainPage_EN } // 根路径的组件，可以根据需要调整
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const lang = navigator.language;

  // 更新页面标题
  if (to.meta && typeof to.meta.title === 'string') {
    document.title = to.meta.title;
  }

  // 定义各语言的路径
  const languagePaths = {
    'zh-CN': '/chs',
    'zh-Hans': '/chs',
    'zh-TW': '/cht',
    'zh-Hant': '/cht',
    'ja': '/ja',
    'default': '/en'
  };

  // 如果当前路径为根路径，则进行语言匹配重定向
  if (to.path === '/') {
    let targetPath = languagePaths.default; // 默认路径为英文
    for (const [key, value] of Object.entries(languagePaths)) {
      if (lang.startsWith(key)) {
        targetPath = value;
        break;
      }
    }
    next(targetPath);
  } else {
    // 其他情况正常跳转
    next();
  }

  // console.log('Navigator language:', navigator.language);
  // console.log('Destination path:', to.path);
});

export default router;
