import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import VueSplide from '@splidejs/vue-splide';


// 创建 Vue 应用实例
const app = createApp(App);
// 使用 Pinia
app.use(createPinia());
// 使用 router
app.use(router);


//使用轮播窗
app.use( VueSplide );


// 挂载 Vue 应用
app.mount('#app');

